var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { default as Tooltip } from '@coreui/coreui/js/dist/tooltip';
import { default as Modal } from '@coreui/coreui/js/dist/modal';
import { AXIOS } from '../utils/Axios';
import PDFObject from 'pdfobject';
var APPROVED = 1;
var DECLINED = 2;
var APPROVED_BTN_ID = 'corpDealApprovedBtn';
var DECLINED_BTN_ID = 'corpDealDeclinedBtn';
var DECLINE_REASON_BTN_ID = 'declineReasonBtn';
var cbxSelector = 'input[type=checkbox].cbx-corp-deal';
var detailBtnSelector = 'detailBtn';
var checkedCond = ':checked';
var enabledCond = ':not(:disabled)';
var initUserInfoTooltip = function () {
    var elm = document.querySelectorAll('[data-toggle="tooltip"]');
    elm.forEach(function (element) {
        var tooltip = new Tooltip(element);
    });
};
var initToggleCtrlBtns = function () {
    document.querySelectorAll("" + cbxSelector).forEach(function (element) {
        element.addEventListener('change', function () {
            var ctrlBtns = Array.from(document.getElementsByClassName('btn-corp-deal-ctrl'));
            var isDisabled = document.querySelectorAll("" + cbxSelector + checkedCond + enabledCond).length <= 0;
            ctrlBtns.forEach(function (e) {
                e.disabled = isDisabled;
            });
        });
    });
};
var initToggleCheckboxes = function () {
    var elm = document.getElementById('corpDealCbxAll');
    if (!elm)
        return;
    elm.addEventListener('change', function () {
        var cbxElms = document.querySelectorAll("" + cbxSelector + enabledCond);
        cbxElms.forEach(function (el) {
            el.checked = elm.checked;
            el.dispatchEvent(new Event('change'));
        });
    });
};
var initToggleModalBtns = function () {
    var elements = Array.from(document.getElementsByClassName("" + detailBtnSelector));
    var deals = document.DEALS;
    elements.forEach(function (element) {
        element.addEventListener('click', function () {
            var dealID = element.getAttribute('data-dealID');
            var deal = deals.find(function (deal) {
                return deal['deal_id'] === parseInt(dealID);
            });
            var isHiding = !deal['is_visible_personal_information'] || deal['uploaded_file_key1'] === '';
            if (isHiding)
                return;
            renderFilesDetail(deal);
            var pdfObjects = Array.from(document.getElementsByClassName('boxPDF'));
            pdfObjects.forEach(function (object) {
                var pdfData = object.getAttribute('data-pdf');
                PDFObject.embed(pdfData, object);
            });
            var detailModalElement = document.getElementById('detailModal');
            var detailModal = new Modal(detailModalElement);
            detailModal.show();
        });
    });
};
var renderFilesDetail = function (deal) {
    var container = document.getElementById('filesContainer');
    container.innerHTML = '';
    var apiUrl = '/deals/showFile?key=';
    for (var i = 1; i <= 5; i++) {
        if (deal["uploaded_file_key" + i] === '')
            return;
        if (i !== 1) {
            container.innerHTML += '<hr class="my-4">';
        }
        var element = document.createElement('div');
        var titleString = "<h5 class=\"font-weight-bold f15\">\u30D5\u30A1\u30A4\u30EB" + i + "</h5>";
        element.innerHTML += titleString;
        element.classList.add('p-2');
        var dataUrl = apiUrl + deal["uploaded_file_key" + i];
        var htmlString = '';
        if (deal["is_uploaded_file_key" + i + "csv"]) {
            htmlString = "\n        <div class=\"p-3 w-25\">\n          <a href=\"" + dataUrl + "\">\n            <img class=\"img-fluid border float-left\" src=\"/public/img/icon-csv.svg\"></img>\n          </a>\n          <span class=\"badge badge-warning mt-3 f14\" style=\"font-size:13px;\">\u203BExcel\u3067\u958B\u304F\u3068\u304D\u306F\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u306B\u5341\u5206\u6CE8\u610F\u3092\u3057\u3066\u304F\u3060\u3055\u3044</span>\n        </div>\n      ";
        }
        else if (deal["is_uploaded_file_key" + i + "pdf"]) {
            htmlString = "\n        <div class=\"text-center\">\n          <div class=\"boxPDF\" data-pdf=\"" + dataUrl + "\"></div>\n          <p class=\"text-right\">\n            <a target=\"_blank\" href=\"" + dataUrl + "\" class=\"btn btn-sm btn-primary mt-3\">\n              <span class=\"cui-external-link\" aria-hidden=\"true\"></span> \u5225\u30A6\u30A3\u30F3\u30C9\u3067\u958B\u304F\n            </a>\n          </p>\n        </div>\n      ";
        }
        else {
            htmlString = "\n        <div class=\"text-center\">\n          <a target=\"_blank\" href=\"" + dataUrl + "\">\n            <img class=\"border img-fluid\" src=\"" + dataUrl + "\"></img>\n          </a>\n        </div>\n      ";
        }
        element.innerHTML += htmlString;
        container === null || container === void 0 ? void 0 : container.appendChild(element);
    }
};
var getCheckedDealIDs = function () {
    var checkedElements = Array.from(document.querySelectorAll("" + cbxSelector + checkedCond));
    var dealIds = checkedElements.map(function (el) { return parseInt(el.value, 10); });
    return dealIds;
};
var submitCorpDeals = function (btnID) { return __awaiter(void 0, void 0, void 0, function () {
    var btnEl, checkedDealIDs, params, data, url, reasonTxtElement, reasonTxt, sendReasonCbx, isSendableEmailWithReason, errMsgEl, res, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                btnEl = document.getElementById(btnID);
                if (!btnEl)
                    return [2 /*return*/];
                checkedDealIDs = getCheckedDealIDs();
                if (checkedDealIDs.length === 0)
                    return [2 /*return*/];
                params = new URLSearchParams();
                data = {};
                url = '/deals/processCorporationDealsCheck';
                reasonTxtElement = document.getElementById('corpJudgeDeclineReasonTxt');
                reasonTxt = reasonTxtElement.value;
                sendReasonCbx = document.getElementById('sendReasonCbx');
                isSendableEmailWithReason = sendReasonCbx ? sendReasonCbx.checked : false;
                errMsgEl = document.getElementById('declineReasonErrMsg');
                switch (btnID) {
                    case APPROVED_BTN_ID:
                        data.judge_result = APPROVED;
                        data.deal_ids = checkedDealIDs;
                        break;
                    case DECLINED_BTN_ID:
                        if (!reasonTxt) {
                            errMsgEl.classList.add('text-danger');
                            errMsgEl.classList.remove('d-none');
                            return [2 /*return*/];
                        }
                        data.judge_result = DECLINED;
                        data.deal_ids = checkedDealIDs;
                        data.is_sendable_email_with_reason = isSendableEmailWithReason;
                        data.decline_reason = reasonTxt;
                        break;
                }
                params.append('data', JSON.stringify(data));
                if (!confirm('データを一括更新します、よろしいですか？'))
                    return [2 /*return*/];
                btnEl.disabled = true;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, 4, 5]);
                return [4 /*yield*/, AXIOS.post(url, params)];
            case 2:
                res = _a.sent();
                if (res.data.status === 200) {
                    return [2 /*return*/];
                }
                btnEl.disabled = false;
                throw new Error(res.data.message);
            case 3:
                err_1 = _a.sent();
                alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
                btnEl.disabled = false;
                throw err_1;
            case 4:
                location.reload(true);
                return [7 /*endfinally*/];
            case 5: return [2 /*return*/];
        }
    });
}); };
var addEventForCtrlBtn = function (btnID) {
    var btnEl = document.getElementById(btnID);
    if (!btnEl)
        return;
    btnEl.addEventListener('click', function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, submitCorpDeals(btnID)];
    }); }); });
};
var isChbxSendableEmail = function (checkedDealIDs) {
    var deals = document.DEALS;
    var isDisabledCbx = true;
    var isDisplayableWarning = false;
    checkedDealIDs.forEach(function (dealID) {
        var deal = deals.find(function (deal) {
            return deal['deal_id'] === dealID;
        });
        isDisabledCbx = deal['is_sendable_email_with_reason'] ? false : isDisabledCbx;
        isDisplayableWarning = !deal['is_sendable_email_with_reason'] ? true : isDisplayableWarning;
    });
    return [isDisabledCbx, isDisplayableWarning];
};
var renderChbxSendableEmail = function (btnID) {
    var btnEl = document.getElementById(btnID);
    if (!btnEl)
        return;
    var checkedDealIDs = getCheckedDealIDs();
    if (checkedDealIDs.length === 0)
        return;
    var _a = isChbxSendableEmail(checkedDealIDs), isDisabledCbx = _a[0], isDisplayableWarning = _a[1];
    var txtWarnElement = document.getElementById('sendReasonWarning');
    var sendReasonCbx = document.getElementById('sendReasonCbx');
    sendReasonCbx.disabled = isDisabledCbx;
    sendReasonCbx.checked = false;
    txtWarnElement.classList.add('d-none');
    if (isDisplayableWarning && !isDisabledCbx)
        txtWarnElement.classList.remove('d-none');
};
var addEventForDeclineReasonBtn = function (btnID) {
    var btnEl = document.getElementById(btnID);
    if (!btnEl)
        return;
    btnEl.addEventListener('click', function () { return renderChbxSendableEmail(btnID); });
};
export default (function () {
    initUserInfoTooltip();
    initToggleCtrlBtns();
    initToggleCheckboxes();
    initToggleModalBtns();
    addEventForCtrlBtn(APPROVED_BTN_ID);
    addEventForCtrlBtn(DECLINED_BTN_ID);
    addEventForDeclineReasonBtn(DECLINE_REASON_BTN_ID);
    window.addEventListener('unload', function () {
        window.scrollTo(0, 0);
    });
});
