var selectRoleChange = function ($event) {
    var roleId = Number($event.target.value);
    document.querySelector('#companyno_area').style.display =
        roleId === document.ROLE_CORPORATION ? 'block' : 'none';
    document.querySelector('#tracking_code_area').style.display =
        roleId === document.ROLE_CORPORATION ? 'block' : 'none';
};
export default (function () {
    var elmSelectRole = document.querySelector('select[name="role_id"]');
    if (elmSelectRole) {
        elmSelectRole.addEventListener('change', selectRoleChange);
        var eventChange = new Event('change');
        elmSelectRole.dispatchEvent(eventChange);
    }
});
