import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@coreui/coreui/js/dist/class-toggler';
import '@coreui/coreui/js/dist/sidebar';
import './scss/style.scss';
import URLLoader from './ts/utils/URLLoader';
import dateRangePicker from './ts/utils/InputDateRangePicker';
import CorporationDeal from './ts/pages/CorporationDeal';
import User from './ts/pages/user';
import setValidatePassword from './ts/utils/PasswordValidator';
document.addEventListener('DOMContentLoaded', function () {
    URLLoader([
        {
            pattern: /\/deals\/search/,
            page: function () {
                dateRangePicker();
                CorporationDeal();
            }
        },
        {
            pattern: /\/user\/add/,
            page: User
        },
        {
            pattern: /\/user\/edit/,
            page: User
        },
        {
            pattern: /\/user\/editPassword/,
            page: function () {
                setValidatePassword('.js-passwordValidation', '#new-password-confirmation', '#old-password');
            }
        },
        {
            pattern: /\/user\/search/,
            page: function () {
                dateRangePicker();
            }
        }
    ]);
});
