var $ = require('jquery');
export default function setValidatePassword(selector1, selector2, selector3) {
    function delay(callback, ms) {
        var timer = 0;
        return function () {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                callback.apply(context, args);
            }, ms || 0);
        };
    }
    $(selector1).on('keyup', delay(function (e) {
        validatePassword($(this), selector2, selector3);
    }, 200));
    $(selector2).on('keyup', delay(function (e) {
        validatePassword(selector1, $(this), selector3);
    }, 200));
    $(selector3).change(delay(function (e) {
        validatePassword(selector1, selector2, selector3);
    }, 200));
    validatePassword(selector1, selector2, selector3);
}
function validatePassword(elem1, elem2, elem3) {
    var password = $(elem1).val(), $submitTrigger = $('.js-passwordValidationTrigger'), $passwordConfirm = $(elem2).val(), $passwordMatchTxt = $('#passwordMatch_txt'), $elem3Val = (elem3 === '#agree') ? $(elem3).is(':checked') : $(elem3).val(), $itemBlockPassword = $('#itemBlockPassword'), $itemBlockConfirmPassword = $('#itemBlockConfirmPassword'), $errorPassword = $("#errorPasswordShow"), $errorComfirmPassword = $("#errorComfirmPasswordShow");
    $errorPassword.show();
    function removeClass($itemBlock) {
        $itemBlock.removeClass(function (index, className) {
            return (className.match(/\bis-\S+/g) || []).join(' ');
        });
    }
    function enableSubmitButton(password, passwordConfirm) {
        var $confirmPasswordIsError = $errorComfirmPassword.html();
        removeClass($itemBlockPassword);
        removeClass($itemBlockConfirmPassword);
        $errorPassword.hide();
        if (password === passwordConfirm) {
            $passwordMatchTxt.html('');
            $errorComfirmPassword.hide();
            $elem3Val ? $submitTrigger.prop("disabled", false) : $submitTrigger.prop("disabled", true);
        }
        else if (!$passwordConfirm && $confirmPasswordIsError) {
            $passwordMatchTxt.html('');
            $submitTrigger.prop("disabled", true);
            $itemBlockConfirmPassword.addClass('is-error');
            $errorComfirmPassword.show();
        }
        else if (!$passwordConfirm && !$confirmPasswordIsError) {
            $passwordMatchTxt.html('');
            $submitTrigger.prop("disabled", true);
        }
        else {
            $itemBlockConfirmPassword.addClass('is-error');
            $passwordMatchTxt.html('パスワードと一致しません');
            $errorComfirmPassword.hide();
            $submitTrigger.prop("disabled", true);
        }
    }
    // status change
    function statusChange(status) {
        var $strength = $('.js-passwordValidationStrength'), $strengthTxt = $('.js-passwordValidationStrength__txt'), $passwordIsError = $errorPassword.html();
        removeClass($strength);
        if (status == 'error') {
            $strengthTxt.html('不可');
            $strength.addClass('is-error');
            $submitTrigger.prop("disabled", true);
            if (password == $passwordConfirm || !$passwordConfirm) {
                $passwordMatchTxt.html('');
                $errorComfirmPassword.show();
            }
            else {
                $itemBlockConfirmPassword.addClass('is-error');
                $passwordMatchTxt.html('パスワードと一致しません');
                $errorComfirmPassword.hide();
            }
            $itemBlockPassword.addClass('is-error');
        }
        else if (status == 'low') {
            $strength.addClass('is-low');
            $strengthTxt.html('弱');
            $submitTrigger.prop("disabled", false);
            enableSubmitButton(password, $passwordConfirm);
        }
        else if (status == 'mid') {
            $strength.addClass('is-mid');
            $strengthTxt.html('中');
            $submitTrigger.prop("disabled", false);
            enableSubmitButton(password, $passwordConfirm);
        }
        else if (status == 'high') {
            $strength.addClass('is-high');
            $strengthTxt.html('強');
            $submitTrigger.prop("disabled", false);
            enableSubmitButton(password, $passwordConfirm);
        }
        else {
            $strengthTxt.html('-');
            $submitTrigger.prop("disabled", true);
            if ($passwordIsError) {
                $itemBlockPassword.addClass('is-error');
            }
        }
    }
    var passwordLength = password ? password.length : 0, itemCheckFlag = {
        is_number: false,
        is_symbol: false,
        is_english: false
    }, checkFlagCount = 0, $elem3Error = $("#elem3ErrorShow"), $elem3IsError = $elem3Error.html(), $itemBlock3 = $('#itemBlockElem3');
    if ($elem3IsError) {
        $elem3Error.show();
        $itemBlock3.addClass('is-error');
    }
    else if ($elem3Val) {
        removeClass($itemBlock3);
        $elem3Error.hide();
    }
    // Non input check
    if (!passwordLength) {
        statusChange('');
        return;
    }
    // error check
    if (passwordLength < 8 || 30 < passwordLength || !password.match(/[^0-9a-zA-Z#$~\-=\?@[\]_]/) == false) {
        statusChange('error');
        return;
    }
    // word number check    
    if (password.match(/[0-9]/)) {
        itemCheckFlag.is_number = true;
    }
    // word symbol check
    // #$-=?@[]_    
    if (password.match(/[#|$|~|\-|=|?|@|[|\]|_]/)) {
        itemCheckFlag.is_symbol = true;
    }
    // word txt check    
    if (password.match(/[a-zA-Z]/)) {
        itemCheckFlag.is_english = true;
    }
    //combination check
    $.each(itemCheckFlag, function () {
        if ($(this)[0]) {
            checkFlagCount++;
        }
    });
    //password strength setting
    if (checkFlagCount == 2) {
        if (8 <= passwordLength && passwordLength < 14) {
            statusChange('low');
        }
        else if (14 <= passwordLength) {
            statusChange('mid');
        }
        return;
    }
    else if (checkFlagCount == 3) {
        if (8 <= passwordLength && passwordLength < 14) {
            statusChange('mid');
        }
        else if (14 <= passwordLength) {
            statusChange('high');
        }
    }
}
;
