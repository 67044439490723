import 'daterangepicker';
import $ from 'jquery';
import { subDays, subMonths, startOfMonth, lastDayOfMonth } from 'date-fns';
export default function dateRangePicker() {
    var now = new Date();
    var displayElements = Array.from(document.getElementsByClassName('dateRangePicker'));
    displayElements.forEach(function (element) {
        var fromElement = document.getElementById(element.id + "_from");
        var toElement = document.getElementById(element.id + "_to");
        if (fromElement.value != '' && toElement.value != '') {
            element.value = fromElement.value + " \u301C " + toElement.value;
        }
    });
    $('input.dateRangePicker').daterangepicker({
        autoUpdateInput: false,
        alwaysShowCalendars: true,
        locale: {
            applyLabel: '適用',
            cancelLabel: 'リセット',
            customRangeLabel: '範囲指定',
            format: 'YYYY-MM-DD'
        },
        ranges: {
            本日: [now, now],
            直近7日: [subDays(now, 6), now],
            今月: [startOfMonth(now), lastDayOfMonth(now)],
            先月: [startOfMonth(subMonths(now, 1)), lastDayOfMonth(subMonths(now, 1))]
        }
    });
    $('input.dateRangePicker').on('apply.daterangepicker', function (ev, picker) {
        var fromElement = document.getElementById(ev.target.id + "_from");
        var toElement = document.getElementById(ev.target.id + "_to");
        fromElement.value = picker.startDate.format('YYYY-MM-DD');
        toElement.value = picker.endDate.format('YYYY-MM-DD');
        ev.target.value = picker.startDate.format('YYYY-MM-DD') + " \u301C " + picker.endDate.format('YYYY-MM-DD');
    });
    $('input.dateRangePicker').on('cancel.daterangepicker', function (ev, picker) {
        var fromElement = document.getElementById(ev.target.id + "_from");
        var toElement = document.getElementById(ev.target.id + "_to");
        fromElement.value = '';
        toElement.value = '';
        ev.target.value = '';
    });
    // single daterangepicker
    $('input.calDatePicker').daterangepicker({
        singleDatePicker: true,
        locale: {
            format: 'YYYY-MM-DD'
        }
    });
    if (document.URL.match('/search')) {
        $('input.calDatePicker').val('');
    }
}
